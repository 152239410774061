<template>
  <div
    :id="tabId"
    :class="['tab-pane', { active: active }]"
    aria-labelledby="tab-acceptance-tab"
    role="tabpanel"
  >
    <div
      v-if="!loading"
      class="row"
    >
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h1>{{ title }}</h1>
            <div><span :class="['badge', document.status == 2 ? 'bg-success' : 'bg-danger']">Document {{ document.status == 2 ? 'signat' : 'pendent' }}</span> <small
              v-if="document.status == 2"
              class="ms-1"
            >{{ document.signed_date }}</small></div>
          </div>
          <div class="card-body">
            <h2 class="text-primary">
              {{ subTitle }}
            </h2>
            <div
              v-if="document.is_acceptance_document"
              v-html="document.acceptance_report"
            />
            <div
              v-else
              v-html="document.justification_report"
            />
            <hr>
            <p><strong class="d-block">Universitat:</strong>{{ user.host_name }}</p>
            <p><strong class="d-block">Premiat/da:</strong>{{ user.user.name }}</p>
            <p><strong class="d-block">Any convocatòria:</strong>{{ user.convo.year }}</p>
            <p v-if="!document.is_acceptance_document">
              <strong class="d-block">Any a justificar:</strong> {{ document.year }}
            </p>
            <p><strong class="d-block">Àrea ICREA:</strong>{{ user.area_name }}</p>
            <hr>
            <template
              v-if="document.is_acceptance_document"
            >
              <ol class="list__alternative">
                <li>
                  <ol type="a">
                    <li>La universitat accepta l'ajut i el cànon.</li>
                    <li>L'investigador/a premiat/da accepta el premi.</li>
                  </ol>
                </li>
                <li>La {{ user.host_name }} i el Dr./a. {{ user.user.last_name }}, investigador/a premiat/da a la convocatòria {{ user.convo.title }}, certifiquen que el Dr./a. {{ user.user.last_name }}, compleix els requisits següents:
                  <ol type="a">
                    <li>Està en possessió del títol de doctor/a.</li>
                    <li>Té una vinculació permanent i a temps complet a la {{ user.host_name }}.</li>
                    <li>Es troba en situació de servei actiu a la {{ user.host_name }} i hi està impartint docència de manera regular.</li>
                  </ol>
                </li>
                <li>La {{ user.host_name }} i el Dr./a. {{ user.user.last_name }} declaren que no estan inclosos en cap de les prohibicions previstes a l' <a
                  href="https://www.icrea.cat/sites/default/files/article_13_llei_38-203.pdf"
                  title="go to page"
                  target="_blank"
                >article 13 de la llei 38/2003, de 17 de novembre</a>, general de subvencions, que impedeixen obtenir la condició de beneficiari/a d'una subvenció.</li>
              </ol>
            </template>
            <template
              v-else
            >
              <ol class="list__alternative">
                <li>La {{ user.host_name }} i el Dr./a. {{ user.user.last_name }}, investigador/a premiat/da a la convocatòria {{ user.convo.title }}, ratifiquen que durant el {{ document.year }} el Premiat/da ha complert els requisits següents:</li>
                <ol type="a">
                  <li>Està en possessió del títol de doctor/a.</li>
                  <li>Ha tingut vinculació permanent i a temps complet a la {{ user.host_name }}.</li>
                  <li>S'ha trobat en situació de servei actiu a la {{ user.host_name }}.</li>
                </ol>
                <li>La {{ user.host_name }} i el Dr./a. {{ user.user.last_name }} declaren que no estan inclosos en cap de les prohibicions previstes a l’article 13 de la llei 38/2003, de 17 de novembre general de subvencions, que impedeixen obtenir la condició de beneficiari/a d’una subvenció.</li>
                <li>La {{ user.host_name }} adjunta la memòria científica sobre les activitats de recerca que ha desenvolupat el Dr./a. {{ user.user.last_name }} al llarg de l’últim any. ICREA en farà una avaluació i comunicarà el resultat a la universitat. Una avaluació positiva és condició necessària per a la permanència al Programa.</li>
                <li>La {{ user.host_name }} declara que les quantitats percebudes s'han registrat a la comptabilitat de la {{ user.host_name }}; que s'han destinat als diferents conceptes per als quals han estat atorgades i que la documentació original acreditativa de la despesa realitzada es troba a les dependències de la {{ user.host_name }}, a disposició d’ICREA.</li>
              </ol>
            </template>
            <hr>
            <template v-if="document.status == 2">
              <p>La universitat i el/la premiat/da donen fe de l'autenticitat dels punts anteriors signant el present document a {{ document.signed_date_format_cat }}.</p>
              <div class="row mb-3">
                <div class="col-sm-6">
                  <p><strong class="d-block">Nom i cognoms:</strong>{{ user.user.name }}</p>
                  <p><strong class="d-block">Premiat/da de la:</strong>{{ user.host_name }}</p>
                </div>
                <div class="col-sm-6">
                  <p><strong class="d-block">Nom i cognoms:</strong>{{ document.lr_name }}</p>
                  <p><strong class="d-block">Representant legal de la:</strong>{{ user.host_name }}</p>
                  <p><strong class="d-block">Càrrec:</strong>{{ document.lr_position }}</p>
                </div>
              </div>
            </template>
            <template v-if="document.is_acceptance_document && document.status === 2">
              <div class="form-check mb-2">
                <input
                  id="inlineCheckbox1"
                  class="form-check-input"
                  type="checkbox"
                  value="checked"
                  :checked="user.nff_requirements"
                  disabled=""
                >
                <label
                  class="form-check-label"
                  for="inlineCheckbox1"
                >En cas de no complir amb els requeriments prèviament mencionats, marqueu aquesta casella.</label>
              </div>
              <div class="form-check mb-2">
                <input
                  id="inlineCheckbox2"
                  class="form-check-input"
                  type="checkbox"
                  value="checked"
                  :checked="user.n_authorized_image"
                  disabled=""
                >
                <label
                  class="form-check-label"
                  for="inlineCheckbox2"
                >L’investigador/a premiat/da <strong>NO autoritza a ICREA</strong> a la publicació de la seva imatge i de les seves dades personals relacionades amb la seva condició com a premiat/da de la Convocatòria ICREA Acadèmia en curs, al seu lloc web <a
                  href="https://www.icrea.cat"
                  target="_blank"
                  class="text-dark text-decoration-underline"
                >https://www.icrea.cat</a>, per tal de difondre i informar de la seva activitat. Per tant, si no vol que es publiquin les seves dades, marqui aquesta casella.</label>
              </div>
              <hr>
            </template>
            <div
              v-if="document.status == 2"
              class="row"
            >
              <div
                v-if="document.document && document.document.url"
                class="col-3"
              >
                <h4>Document {{ !document.is_acceptance_document ? 'de justificació anual' : '' }}</h4>
                <p><a
                  :href="document.document.url"
                  target="_blank"
                  title="view document"
                  class="btn bg-light-secondary btn-sm"
                ><i data-feather="eye" /> Veure el document</a></p>
              </div>
              <div
                v-if="!document.is_acceptance_document && document.scientific_memory && document.scientific_memory.url"
                class="col-3"
              >
                <h4>Memòria científica</h4>
                <p><a
                  :href="document.scientific_memory.url"
                  target="_blank"
                  title="view document"
                  class="btn bg-light-secondary btn-sm"
                ><i data-feather="eye" /> Veure el document</a></p>
              </div>
              <div
                v-if="!document.is_acceptance_document && (user.canViewEconomic || checkAdminEconomic) && (user.economy_document && user.economy_document.url)"
                class="col-3"
              >
                <h4>Memòria econòmica</h4>
                <p><a
                  :href="user.economy_document.url"
                  target="_blank"
                  title="view document"
                  class="btn bg-light-secondary btn-sm"
                ><i data-feather="eye" /> Veure el document</a></p>
              </div>
              <div
                v-if="!document.is_acceptance_document && (user.canViewEconomic || checkAdminEconomic) && (user.economy_document_resum && user.economy_document_resum.url)"
                class="col-3"
              >
                <h4>Resum memòria econòmica</h4>
                <p><a
                  :href="user.economy_document_resum.url"
                  target="_blank"
                  title="view document"
                  class="btn bg-light-secondary btn-sm"
                ><i data-feather="eye" /> Veure el document</a></p>
              </div>
              <div
                v-if="loggedUser.roles.includes('super-admin')"
                class="col-12 mt-2"
              >
                <button
                  class="btn btn-danger"
                  @click="preRollbackJustificacio"
                >
                  Unsubmit
                </button>
              </div>
            </div>
            <Form
              v-else-if="document.status != 2 && loggedUser.roles.includes('super-admin') || (loggedUser.roles.includes('university') && user.can_justify)"
              :host-name="user.host_name"
              :document="document"
              :is-acceptance-document="document.is_acceptance_document"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Form from './Form.vue'

export default {
  components: {
    Form,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    document: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    tabId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkAdminEconomic: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
  watch: {
    'document.id': function () {
      if (this.loggedUser.roles.includes('super-admin')) {
        if (this.user.documents[this.user.documents.length - 1].id === this.document.id) {
          this.checkAdminEconomic = true
        } else {
          this.checkAdminEconomic = false
        }
      }
    },
  },
  mounted() {
    if (this.loggedUser.roles.includes('super-admin')) {
      if (this.user.documents[this.user.documents.length - 1].id === this.document.id) {
        this.checkAdminEconomic = true
      } else {
        this.checkAdminEconomic = false
      }
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    async preRollbackJustificacio() {
      Vue.swal({
        title: 'Segur que vols cancel·lar la justificació?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si!',
      }).then(result => {
        if (result.isConfirmed) {
          this.rollbackJustificacio()
        }
      })
    },
    async rollbackJustificacio() {
      this.loading = true
      try {
        await this.$store.dispatch('academyAwarded/rollbackJustification', this.document.id)
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
}
</script>
